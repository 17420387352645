<!--
All the additional containers were meant to achieve the animating border effect.
Unfortunately CSS does not support animation gradients
-->
<div class="border">
  <div class="container">
    <div class="card">
      <h2>Ryan de Gruyter</h2>
      <p>IT Consulting</p>
    </div>
  </div>
</div>

